import React, { Component } from 'react';
import Header from "./Header.js";
import Footer from "./Footer.js";
import {Redirect} from 'react-router-dom';
import {api_url} from '../Config.js';
class Orderlisting extends Component {
  constructor(props){
    super(props);
    this.state={
      items:[],
      redirectToReferrer: false,
      user_id:JSON.parse(sessionStorage.getItem("userData")),
      base_url:api_url.base_url,
      
      
    }
  }
  componentDidMount(){

    const apiUrl = this.state.base_url+`/welcome/getOrderListing?user_id=${this.state.user_id}`;
    fetch(apiUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            items: result
          });
          console.log(this.state.items)
        },
        (error) => {
          this.setState({ error });
        }
      )

  }
   componentWillMount() {
if(sessionStorage.getItem("userData")){
this.getUserData();
}
else{
this.setState({redirectToReferrer: true});
}
}
getUserData() {
JSON.parse(sessionStorage.getItem("userData"));


}
  
	render() {
  const {items} = this.state;
  if (this.state.redirectToReferrer) {
return (<Redirect to={`/login-urdu/1`}/>)
}
		return (
			<div>
			<Header />
				<div className="main_body">
  
  <div className="login_screen order_lists" id="order_lists_load">
    
    
      <div className="user_login Select_language">
        
      <div className="form_box select_items_pdt">
        <h3>Order List</h3>
      </div>
      {items.map(item=>(

      <div className="row product_layout" key={item.id}>
        <div className="col-sm-2 product_img">
          <img src={this.state.base_url+`/images/${item.item_image}`} /> 
        </div>
        <div className="col-sm-8 product_contents">
          <h4>{item.item_name}</h4>
          <div className="quantity_box">
          <p>{item.datetime}</p>
        </div>

        </div>
        <div className="col-sm-2 product_price">
        <b>{item.time_selection}</b>
          <p>Active</p>
        </div>
      </div>



        

        ))}
      

    </div>
  </div>
</div>
<Footer />
			</div>
		);
	}
}

export default Orderlisting;
