import React, { Component } from 'react';
import $ from 'jquery';
import grocery  from "../assets/images/grocery_graphic.png";
import checkbox  from "../assets/images/checkbox-icon.png";
import Header from "./Header.js";
import Footer from "./Footer.js";
import {NavLink} from 'react-router-dom';
import {PostData} from './SubmitOrder.js';
import {Redirect} from 'react-router-dom';
import {api_url} from '../Config.js';
class AddressDetail extends Component {

constructor(props) {
super(props);
this.state = {
data:[],
redirectToReferrer: false,
hours:localStorage.getItem('hours'),
products:[],
users:[],
item_name:this.props.match.params.item_id,
user_id:JSON.parse(sessionStorage.getItem("userData")),
base_url:api_url.base_url,
building:'',
buildingErr:'',
street:'',
streetErr:'',
area:'',
areaErr:'',
floor:'',
floorErr:'',
noterider:' ',

};
      this.refresh = this.refresh.bind(this);
}
componentDidMount(){

  const apiUrl = this.state.base_url+'/welcome/getareas';
    fetch(apiUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            products: result
          });
         console.log(this.state.products)
        },
        (error) => {
          this.setState({ error });
        }
      )
    
  
}
componentWillMount() {
if(sessionStorage.getItem("userData")){
this.getUserData();
}
else{
this.setState({redirectToReferrer: true});
}
}
getUserData() {
JSON.parse(sessionStorage.getItem("userData"));


}
validation(){
  if(this.state.building=="" && this.state.floor=="" && this.state.street=="" && this.state.area==""){
    this.setState(
    {
      buildingErr:"Enter Your Building Address",
      floorErr:"Enter Your Floor Address",
      streetErr:"Enter Your Street",
      areaErr:"Enter Your Area",
     
    })
  }else if(this.state.building==""){
    this.setState(
    {
      buildingErr:"Enter Your Building Address",
      floorErr:"",
      streetErr:"",
      areaErr:"",
      
    })
  }
  else if(this.state.floor==""){
    this.setState(
    {
      
      buildingErr:"",
      floorErr:"Enter Your Floor Address",
      streetErr:"",
      areaErr:"",
      
     
    })
  }else if(this.state.street=="")
  {
    this.setState(
    {
      
      buildingErr:"",
      floorErr:"",
      streetErr:"Enter Your Street",
      areaErr:"",
      
     
    })
  }else if(this.state.area=="")
  {
    this.setState(
    {
      
      buildingErr:"",
      floorErr:"",
      streetErr:"",
      areaErr:"Enter Your Area",
      
      
    })
  }
  else{

    return true
  }
}
refresh(e) 
{
  if(this.validation())
  {


      // $(".request_door_click").on('click', function()
      // {
          
      // });

      // $(".request_door_click").click(function(){
        $(".process_complete").hide();
      // });
      
      // $(".request_door_click").click(function(){
        $(".last_step").show();
      // });

          this.setState({
            buildingErr:"",
            streetErr:"",
            floorErr:"",
            areaErr:""

        })

    PostData(this.state)
      

   

    }  

  }

render() {
 
  const { products} = this.state;
  const { users } = this.state.users;
if (this.state.redirectToReferrer) {
return (<Redirect to={`/login/${this.props.match.params.item_id}`}/>)
}
		return (
<div>
<Header />  

    <div className="main_body">
  <div className="login_screen">
    <div className="logo login_page">
      <a href="#"><img src={grocery} /></a>
    </div>
    
      <div className="user_login Select_language process_complete">
      <div className="form_box_delivery">
        <h3>Delivery Address</h3>
        <div className="row">
          <div className="col-sm-6 delivery_address">
            <label>Building</label>
            <input type="text" placeholder="" name="building" onChange={(event)=>this.setState({building:event.target.value})} />
        <p className="detailErrMsg">{this.state.buildingErr}</p>
          </div>
          <div className="col-sm-6 delivery_address">
            <label>Street</label>
            <input type="text" placeholder="" name="street" onChange={(event)=>this.setState({street:event.target.value})} />
          <p className="detailErrMsg">{this.state.streetErr}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 delivery_address">
            <label>Area</label>
            <select name="area" onChange={(event)=>this.setState({area:event.target.value})}>
              <option>Select Area</option>
            {products.map(product=>(
              <option key={product.id} value={product.name}>{product.area_name}</option>

              ))}
            }
            </select>
            <p className="detailErrMsg">{this.state.areaErr}</p>
          </div>
          <div className="col-sm-6 delivery_address">
            <label>Floor/Unit</label>
            <input type="text" placeholder="" name="floor" onChange={(event)=>this.setState({floor:event.target.value})} />
          <p className="detailErrMsg">{this.state.floorErr}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 delivery_address rider_direction">
        
            <label>Note to rider direction / landmark</label>
            <textarea type="text" name="noterider" onChange={(event)=>this.setState({noterider:event.target.value})}></textarea>
            
          </div>
          
        </div>
      </div>
      
      <div className="form_box Request_door" id="request_door">
        <a href="#" className="request_door_click" onClick={this.refresh}>Request shop at door</a>
      </div>
    </div>
    <div className="last_step">
      <img src={checkbox} alt="Check Box Icon" />
      <b>Thank You</b>
      <p>ShopAtDoor is on the way</p>
    </div>
  </div>

<Footer/>
</div>
</div>

		);
	}
}
export default AddressDetail;