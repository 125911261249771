import React, { Component } from 'react';
import grocery  from "../assets/images/grocery_graphic.png";
import Header from "./HeaderUrdu.js";
import Footer from "./FooterUrdu.js";
import {NavLink} from 'react-router-dom';
import {api_url} from '../Config.js';
class ItemTypeUrdu extends Component {
	constructor(props){
    super(props);
    this.state = {
      items:[],
      item_name:'',
      base_url:api_url.base_url,
    }

    this.refresh = this.refresh.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  componentDidMount(){
   
    const apiUrl = this.state.base_url+'/welcome/getitemsurdu';
    fetch(apiUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            items: result
          });
         console.log(this.state.items)
        },
        (error) => {
          this.setState({ error });
        }
      )

  }
  
  
 
 
refresh(e) {
this.setState({[e.target.name]:e.target.className}); 
console.log({[e.target.name]:e.target.className})


    }
 onChange(e){
 
}
   
render() {
  localStorage.setItem('item_name', this.state.item_name);
    const { items} = this.state;
    
    return (
<div>
<Header />	

<div className="main_body items_select_urdu">
  <div className="login_screen">
    <div className="logo login_page">
      <a href="#"><img src={grocery} /></a>
    </div>
    
      <div className="user_login Select_language">
        
      <div className="form_box ">
      <h3>آپ کیا آرڈر کرنا چاہتے ہیں</h3>
       
      </div>
      {items.map(item => (
      <div  id="Grocery" key={item.id}>
        
        <NavLink to={`/time-selection-urdu/${item.id}`} onClick={this.refresh} name="item_name" className={item.item_name} ><img src={this.state.base_url+`/images/${item.item_image}`} /> {item.item_name}  </NavLink>
      </div>
      ))}
    </div>
  </div>
</div>
<Footer/>
</div>
		);
	}
}
export default ItemTypeUrdu;