import React, { Component } from 'react';
import $ from 'jquery';
import grocery  from "../assets/images/grocery_graphic.png";
import checkbox  from "../assets/images/checkbox-icon.png";
import Header from "./HeaderUrdu.js";
import Footer from "./FooterUrdu.js";
import {NavLink} from 'react-router-dom';

import {Redirect} from 'react-router-dom';
import {api_url} from '../Config.js';
class Login extends Component {
  constructor(props) {
super(props);
this.state = {
data:[],
redirectToReferrer: false,
hours:'Now',
items:[],
users:[],
item_name:this.props.match.params.item_id,
user_id:JSON.parse(sessionStorage.getItem("userData")),
base_url:api_url.base_url,
};
 
      this.onChange = this.onChange.bind(this);
}

componentDidMount(){
   
      let userID=this.state.user_id;
   const apiUrl = this.state.base_url+`/welcome/getUserName?userId=${userID}`;
    fetch(apiUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            users: result
          });
          
        },
        (error) => {
          this.setState({ error });
        }
      )

        $(".request_door_click").on('click', function(){
    });
    $(".request_door_click").click(function(){
  $(".process_complete").hide();
});
    $(".request_door_click").click(function(){
  $(".last_step").show();
});
      

  }
 
  
componentWillMount() {

if(sessionStorage.getItem("userData")){
this.getUserData();
}
else{
this.setState({redirectToReferrer: true});
}
}
getUserData() {
JSON.parse(sessionStorage.getItem("userData"));


}
 onChange(e){
localStorage.setItem('hours',e.target.value);

}


render() {
 
  const { items} = this.state;
if (this.state.redirectToReferrer) {
return (<Redirect to={`/login-urdu/${this.props.match.params.item_id}`}/>)
}
    return (
<div>
<Header />	
<div className="main_body">
  <div className="login_screen">
    <div className="logo login_page">
      <a href="#"><img src={grocery} /></a>
    </div>
    
      <div className="user_login Select_language process_complete">
      <div className="welcome_user">
        <h4> {this.state.users.name} خوش آمدید</h4>
       </div>
       
      <div className="form_box ">
        <h3>وقت کا انتخاب</h3>
      </div>
      <div className="custom-control custom-radio">
          <input type="radio" id="row_default_1" name="hours" className="custom-control-input" value="Now" checked={this.state.hours === 'Now'} onChange={this.onChange} />
           <label htmlFor="row_default_1" className="custom-control-label gj-cursor-pointer"> ابھی</label>
        </div>
        <div className="custom-control custom-radio">
          <input type="radio" id="row_default_2" name="hours" className="custom-control-input" value="1 Hour" onChange={this.onChange} />
           <label htmlFor="row_default_2" className="custom-control-label gj-cursor-pointer">1 گھنٹہ </label>
        </div>
        <div className="custom-control custom-radio">
          <input type="radio" id="row_default_3" name="hours" className="custom-control-input" value="3 Hours" onChange={this.onChange} />
           <label htmlFor="row_default_3" className="custom-control-label gj-cursor-pointer"> 3 گھنٹے</label>
        </div>
      <div className="form_box Request_door" id="request_door">
       <NavLink to={`/address-details-urdu/${this.props.match.params.item_id}`}>جاری رہے </NavLink>
       
        
      </div>
    </div>
    
  </div>
</div>
<Footer/>
</div>
		);
	}
}
export default Login;